import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MtxGridModule } from '@ng-matero/extensions/grid';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MaterialModule } from './material-module';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FileUploadModalComponent } from './components/file-upload-modal/file-upload-modal.component';
import { CustomPipeModule } from 'src/app/shared/pipes/custom-pipe.module';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { LogsFilterComponent } from './components/logs-filter/logs-filter.component';
import { CustomMappingComponent } from './components/custom-mapping/custom-mapping.component';
import { FormularyFileUploadComponent } from './components/formulary-file-upload/formulary-file-upload.component';
import { FormularyFilterationComponent } from './components/formulary-filteration/formulary-filteration.component';
import { DashboardFilterComponent } from './components/dashboard-filter/dashboard-filter.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OrderFilterBydateComponent } from './components/order-filter-bydate/order-filter-bydate.component';
import { OrderTypesComponent } from './components/order-types/order-types.component';
import { ReportComponent } from './components/report/report.component';
import { DashboardCardsComponent } from './components/dashboard-cards/dashboard-cards.component';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { OrderStatusModalComponent } from './components/order-status-modal/order-status-modal.component';
import { OrderItemStatusModalComponent } from './components/order-item-status-modal/order-item-status-modal.component';
import { DataExportModalComponent } from './components/data-export-modal/data-export-modal.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MessageViewComponent } from './components/modal/message-view/message-view.component';
import { AddVendorRatingComponent } from './components/modal/add-vendor-rating/add-vendor-rating.component';
import { GeneratePoComponent } from './components/modal/generate-po/generate-po.component';
import { OrderDiscrepancyComponent } from './components/order-discrepancy/order-discrepancy.component';
import { AllownumbersonlyDirective } from './directive/allownumbersonly.directive';
import { TwodigitdecimalnumberDirective } from './directive/twodigitdecimalnumber.directive';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { LeadtimeComponent } from './components/leadtime/leadtime.component';
import { ReportFilterComponent } from './components/report-filter/report-filter.component';
import { OrderItemEditComponent } from './components/order-item-edit/order-item-edit.component';
import { DownloadModalComponent } from './components/download-modal/download-modal.component';
import { PrintModalComponent } from './components/print-modal/print-modal.component';
import { CustomOrderIdModalComponent } from './components/custom-order-id-modal/custom-order-id-modal.component';


const THIRD_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  AngularEditorModule,
  DateRangePickerModule
];

@NgModule({
  exports: [
    CommonModule,
    ...THIRD_MODULES,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SidebarComponent,
    ConfirmationModalComponent,
    FileUploadModalComponent,
    FormularyFileUploadComponent,
    MaterialFileInputModule,
    MaterialModule,
    NavBarComponent,
    MtxGridModule,
    CustomPipeModule,
    CommonFilterComponent,
    LogsFilterComponent,
    CustomMappingComponent,
    FormularyFilterationComponent,
    DashboardFilterComponent,
    DashboardComponent,
    OrderFilterBydateComponent,
    OrderTypesComponent,
    ReportComponent,
    DashboardCardsComponent,
    OrderStatusModalComponent,
    OrderItemStatusModalComponent,
    DataExportModalComponent,
    MessageViewComponent,
    AddVendorRatingComponent,
    GeneratePoComponent,
    OrderDiscrepancyComponent,
    AllownumbersonlyDirective,
    TwodigitdecimalnumberDirective,
    LeadtimeComponent,
    ReportFilterComponent,
    OrderItemEditComponent,    
    DownloadModalComponent,
    PrintModalComponent,
    CustomOrderIdModalComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    ...THIRD_MODULES,
    MatSlideToggleModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatDatepickerModule,
    MaterialModule,
    MaterialFileInputModule,
    MtxGridModule,
    CustomPipeModule,
    ContentLoaderModule,
    
    
  ],
  declarations: [
    SidebarComponent,
    ConfirmationModalComponent,
    NavBarComponent,
    FileUploadModalComponent,
    CommonFilterComponent,
    LogsFilterComponent,
    CustomMappingComponent,
    FormularyFileUploadComponent,
    FormularyFilterationComponent,
    DashboardFilterComponent,
    DashboardComponent,
    OrderFilterBydateComponent,
    OrderTypesComponent,
    ReportComponent,
    DashboardCardsComponent,
    OrderStatusModalComponent,
    OrderItemStatusModalComponent,
    DataExportModalComponent,
    MessageViewComponent,
    AddVendorRatingComponent,
    GeneratePoComponent,
    OrderDiscrepancyComponent,
    AllownumbersonlyDirective,
    TwodigitdecimalnumberDirective,
    LeadtimeComponent,
    ReportFilterComponent,
    OrderItemEditComponent,
    DownloadModalComponent,
    PrintModalComponent,
    CustomOrderIdModalComponent,

    
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    DatePipe
  ],
})
export class SharedModule { }
