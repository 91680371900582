import * as moment from "moment";
import { customerType, getBaseRoute } from "./constants";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const getCustomerType = (type: string) => {
    return customerType[type];
}

export const getUserBaseRoute = (userRole: string) => {
    return getBaseRoute[userRole]
}

export const formatDateTime = (dateTime: string, format: string) => {
    return moment(dateTime).format(format)
}

// file download function
export const exportData = (fileType: string, data: any, fileName: string) => {
    if (fileType == 'pdf') {
        // var headers = createHeaders(Object.keys(data[0]));
        // var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });
        // doc.table(1, 1, data, headers, { autoSize: true });
        // doc.save(fileName + '.pdf');
    } else if (fileType == 'csv') {
        const replacer = (key: any, value: any) => value === null ? '' : value;
        const header = Object.keys(data[0]);
        let csv = data.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');
        var blob = new Blob([csvArray], { type: 'text/csv' })
        FileSaver.saveAs(blob, fileName + ".csv");
    } else {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const downloadData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(downloadData, fileName + '.xlsx');
    }
}

export const checkPermission = (userPermissions: any, permission: string) => {
    if (userPermissions) {
        const index = userPermissions.findIndex((e: any) => { return e.value === permission })
        if (index != -1) {
            return true;
        }
    }
    return false;
}