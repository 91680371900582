import { Injectable } from '@angular/core';
import { APIResponse } from '@models/api-response-data';
import { LocalStorageService } from '@services/local-storage.service';
import { ApiConstants } from '@utils/api-constants';
import { checkPermission } from '@utils/helper';
import { ApiService } from './api-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  customerLists: any
  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
  ) { }

  public isAuthenticated(): boolean {
    let token: any = this.localStorageService.getItem('accessToken');
    if (token) {
      return true;
    }
    return false;
  }

  getUserType() {
    return this.localStorageService.getItem('userType');
  }

  getCustomerDetails() {
    const customer = this.localStorageService.getItem('customerDetails');
    if (customer) {
      return JSON.parse(customer);
    }
    return null;
  }
  customerList() {
    this.apiService.parseApiCall(ApiConstants.URL.CUSTOMER_DETAILS.URI, ApiConstants.URL.CUSTOMER_DETAILS.METHOD, {

    }).subscribe((res: APIResponse) => {
      if (res.success) {

        this.customerLists = res.data.customer_list;
        return this.customerLists
      }
    });
  }

  getVendorDetails() {
    const vendor = this.localStorageService.getItem('vendorDetails');
    if (vendor) {
      return JSON.parse(vendor);
    }
    return null;
  }

  getUserPermission(entity: string = '') {
    const userPermission: any = this.localStorageService.getItem('userPermission');
    if (userPermission != 'undefined') {
      const permission = JSON.parse(userPermission);
      if (entity != '' && permission[entity]) {
        return permission[entity];
      }
      return permission
    }
    return [];
  }

  checkPermission(entity: string, role: string) {
    const permission = this.getUserPermission()
    return checkPermission(permission[entity], role);
  }

  getUserDetails() {
    const user = this.localStorageService.getItem('userDetails');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }
}

