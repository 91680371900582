import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart, Event as NavigationEvent, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit, OnDestroy {

  isShowBackButton = false;
  backButtonPages = ['/auth/forgot-password', '/auth/signup'];
  userType: number = 0;
  user: string = '';
  public previousUrl!: string;
  public currentUrl!: string;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .subscribe(
        (event: any) => {
          if (event instanceof NavigationStart) {
            if (this.backButtonPages.includes(event.url)) {
              this.isShowBackButton = true;
            } else {
              this.isShowBackButton = false;
            }
          }
          if (event instanceof NavigationEnd) {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
          };
        });
  }
  ngOnInit(): void {
    switch (this.route.snapshot.paramMap.get('user_type')) {
      case 'admin':
        this.userType = 2;
        this.user = 'admin';
        break;
      case 'vendor':
        this.userType = 3;
        this.user = 'vendor';
        break;
      case 'suadmin':
        this.user = 'suadmin';
        this.userType = 1;
        break;
      default:
        break;
    }
    if (!['admin', 'suadmin', 'vendor'].includes(this.user)) {
      this.router.navigate(['/auth/signin'])
    }
    if (this.backButtonPages.includes(this.router.url)) {
      this.isShowBackButton = true;
    }

  }

  ngOnDestroy() {
  }

}
