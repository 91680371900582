import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ModalConfig } from '@models/modal-config';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';
import { APIResponse } from '@models/api-response-data';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-order-status-modal',
  templateUrl: './order-status-modal.component.html',
  styleUrls: ['./order-status-modal.component.scss']
})
export class OrderStatusModalComponent implements OnInit {

  ngbModalRef!: NgbModalRef;
  @Output() success: EventEmitter<string> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public orderData!: any
  @ViewChild('orderStatusUpdate') private modalContent!: TemplateRef<OrderStatusModalComponent>
  private modalRef!: NgbModalRef
  statusUpdateForm!: FormGroup;
  submitted: boolean = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastrService,
    public auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.statusUpdateForm = this.formBuilder.group({
      status: ['', [Validators.required]]
    })
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  get f() {
    return this.statusUpdateForm.controls;
  }

  updateStatus() {
    this.success.emit(this.statusUpdateForm.value.status)
  }
}
