import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { LocalStorageService } from '@services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {
  constructor(
    public auth: AuthenticationService,
    public router: Router,
    private localStorageService: LocalStorageService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data['expectedRole'];
    let user_type: any = this.auth.getUserType();
    if (!this.auth.isAuthenticated()) {
      // to do -> logout action
      return false;
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data['expectedRole'];
    let user_type: any = this.auth.getUserType();
    if (!this.auth.isAuthenticated()) {
      // to do -> logout action
      return false;
    }
    return true;
  }
}
