import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFilterDataSearchItems } from '@models/filter-data';

@Component({
  selector: 'app-logs-filter',
  templateUrl: './logs-filter.component.html',
  styleUrls: ['./logs-filter.component.scss']
})
export class LogsFilterComponent implements OnInit {
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Input() public filterItems!: IFilterDataSearchItems;
  @Input() public searchTerms!:any;
  @Input() public searchTerms1!:any;
  @Input() public searchTerms2!:any;
  @Input() public searchTerms3!:any;
  searchType=false
 
 
  filterForm!: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    console.log(this.filterItems)
    this.filterForm = this.formBuilder.group({
      searchByType: [''],
      searchByAction: [''],
      searchByLevel: ['']
    })
    this.filterForm.valueChanges.subscribe(data => {
    console.log(this.filterForm.value.searchByType)
      if(this.filterForm.value.searchByType=='USERS'){
        this.searchType=true
      
      }
      else{
        this.searchType=false
      }
      console.log(this.searchType)
      this.filter.emit(this.filterForm.value)
    })
  }


}
