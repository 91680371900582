import { NgModule } from '@angular/core';
import { CurrencySymbolPipe } from './currency.pipe';

@NgModule({
  declarations: [
    CurrencySymbolPipe
  ],
  imports: [],
  exports: [
    CurrencySymbolPipe,
  ],
})
export class CustomPipeModule { }
