import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { APIResponse } from '@models/api-response-data';
import { IFilterDataItems } from '@models/filter-data';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';

@Component({
  selector: 'app-formulary-filteration',
  templateUrl: './formulary-filteration.component.html',
  styleUrls: ['./formulary-filteration.component.scss']
})
export class FormularyFilterationComponent implements OnInit {

  filterForm!: FormGroup;
  vendorLists:any

  customerDetails: any
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Input() public filterItems!: IFilterDataItems;
  
  constructor(private apiService: ApiService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (this.filterItems.fields == undefined) {
      this.filterItems.fields = {
        
        primaryVendor:false,
        secondaryVendor:false
        
      }
    }
    this.vendorList();
    this.customerList();
    this.filterForm = this.formBuilder.group({
      customerCode: [''],
      searchBy: [''],
      primaryVendor: [''],
      secondaryVendor: ['']
    })
    this.filterForm.valueChanges.subscribe(data => {
      this.filter.emit(this.filterForm.value)
    })

  }
  customerList() {
    this.apiService.parseApiCall(ApiConstants.URL.CUSTOMER_DETAILS.URI, ApiConstants.URL.CUSTOMER_DETAILS.METHOD, {

    }).subscribe((res: APIResponse) => {
      if (res.success) {
        this.customerDetails = res.data.customer_list;
      }
    });
  }
  vendorList() {
    if (this.filterItems.fields.primaryVendor || this.filterItems.fields.secondaryVendor) {
      this.apiService.parseApiCall(ApiConstants.URL.VENDOR_DROPDOWN_LIST.URI, ApiConstants.URL.VENDOR_LIST.METHOD, {}).subscribe((res: APIResponse) => {
        if (res.success) {
          this.vendorLists = res.data.vendors;
         
        }
      });
    }
  }

}
