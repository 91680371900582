import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { APIResponse } from '@models/api-response-data';
import { ModalConfig } from '@models/modal-config';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api-service.service';
import { AuthenticationService } from '@services/authentication.service';
import { ApiConstants } from '@utils/api-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-vendor-rating',
  templateUrl: './add-vendor-rating.component.html',
  styleUrls: ['./add-vendor-rating.component.scss']
})
export class AddVendorRatingComponent implements OnInit {

  @ViewChild('vendorRatingSubmit') private modalContent!: TemplateRef<AddVendorRatingComponent>
  ngbModalRef!: NgbModalRef;
  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public data!: any;
  private modalRef!: NgbModalRef
  vendorRatingForm!: FormGroup;
  vendorId: any;
  customerDetails: any;

  constructor(
    private modalService: NgbModal,
    public auth: AuthenticationService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.customerDetails = this.auth.getCustomerDetails();
    this.vendorRatingForm = this.formBuilder.group({
      rating: ['1'],
      review: [''],
      customerId: ['']
    })
    if (this.customerDetails) {
      this.vendorRatingForm.patchValue({ customerId: this.customerDetails.id });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['data'].firstChange) {
      this.vendorId = changes['data'].currentValue._id
    }
  }
  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  submitRating() {
    this.apiService.parseApiCall(ApiConstants.URL.POST_VENDOR_RATING.URI + '/' + this.vendorId, ApiConstants.URL.POST_VENDOR_RATING.METHOD, this.vendorRatingForm.value).subscribe((res: APIResponse) => {
      if (res.success) {
        this.toaster.success(res.message)
        this.success.emit(true)
        this.close();
      }
    });
  }
}
