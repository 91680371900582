import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ModalConfig } from '@models/modal-config';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';
import { APIResponse } from '@models/api-response-data';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@services/authentication.service';
import { dataUploadTypes } from '@utils/constants';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent implements OnInit {

  ngbModalRef!: NgbModalRef;
  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public fileData!: any
  @ViewChild('fileUpload') private modalContent!: TemplateRef<FileUploadModalComponent>
  private modalRef!: NgbModalRef
  uploadForm!: FormGroup;
  submitted: boolean = false;
  customerDetails: any;
  customerCode!: string;
  customerLists!: any;
  vendorLists!: any;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastrService,
    public auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.customerList();
    this.vendorList();
    this.customerDetails = this.auth.getCustomerDetails();
    if (this.customerDetails) {
      this.customerCode = this.customerDetails.customerCode;
    } else {
      this.customerCode = this.fileData.customerCode;
    }
    this.uploadForm = this.formBuilder.group({
      file: ['', [Validators.required]],
      uploadType: [this.fileData.uploadType, [Validators.required]],
      customerCode: [this.customerCode ? this.customerCode : ''],
      customerId: [''],
      orderId: [''],
      itemId: [''],
      vendorId: [''],
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['fileData'].firstChange) {
      this.uploadForm.patchValue({
        uploadType: changes['fileData'].currentValue.uploadType
      })
      if (changes['fileData'].currentValue.uploadType) {
        this.uploadForm.patchValue({
          customerCode: changes['fileData'].currentValue.customerCode
        })
      }
      if (changes['fileData'].currentValue.customerId) {
        this.uploadForm.patchValue({
          customerId: changes['fileData'].currentValue.customerId
        })
      }
      if (changes['fileData'].currentValue.orderId) {
        this.uploadForm.patchValue({
          orderId: changes['fileData'].currentValue.orderId
        })
      }
      if (changes['fileData'].currentValue.itemId) {
        this.uploadForm.patchValue({
          itemId: changes['fileData'].currentValue.itemId
        })
      }
    }
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  get f() {
    return this.uploadForm.controls;
  }

  uploadFile() {
    if (this.uploadForm.valid) {
      const formData = new FormData();
      const file_form = this.uploadForm.value.file;
      const file = file_form.files[0];
      formData.append('uploadType', this.uploadForm.value.uploadType);
      if (this.customerCode) {
        formData.append('customerCode', this.customerCode);
      }
      if (this.uploadForm.value.customerId != '') {
        formData.append('customerId', this.uploadForm.value.customerId);
      }
      if (this.uploadForm.value.vendorId != '') {
        formData.append('vendorId', this.uploadForm.value.vendorId);
      }
      formData.append('file', file);
      if (this.uploadForm.value.uploadType === dataUploadTypes.ORDER_ITEM_CREDIT_DATA) {
        this.apiService.parseApiCall(ApiConstants.URL.ORDER.URI + '/' + this.uploadForm.value.orderId + '/upload/credit-note',
          ApiConstants.URL.ORDER.METHOD, formData).subscribe((res: APIResponse) => {
            this.close();
            if (res.success) {
              this.toastService.success('Upload Success')
              this.success.emit()
            }
          });
      } else if (this.uploadForm.value.uploadType === dataUploadTypes.ORDER_ITEM_DEBIT_NOTE) {
        this.apiService.parseApiCall(ApiConstants.URL.ORDER.URI + '/' + this.uploadForm.value.orderId + '/upload/debit-note',
          ApiConstants.URL.ORDER.METHOD, formData).subscribe((res: APIResponse) => {
            this.close();
            if (res.success) {
              this.toastService.success('Upload Success')
              this.success.emit()
            }
          });
      }
      else {
        this.apiService.parseApiCall(ApiConstants.URL.UPLOAD_DATA.URI,
          ApiConstants.URL.UPLOAD_DATA.METHOD, formData).subscribe((res: APIResponse) => {
            this.close();
            if (res.success) {
              this.toastService.success('Upload Success')
              this.success.emit()
            }
          });
      }
    }
  }

  customerList() {
    if (this.fileData.fields.customerName) {
      this.apiService.parseApiCall(ApiConstants.URL.CUSTOMER_DETAILS.URI, ApiConstants.URL.CUSTOMER_DETAILS.METHOD, {
      }).subscribe((res: APIResponse) => {
        if (res.success) {
          this.customerLists = res.data.customer_list;
        }
      });
    }
  }

  vendorList() {
    if (this.fileData.fields.vendorList) {
      this.apiService.parseApiCall(ApiConstants.URL.VENDOR_LIST.URI, ApiConstants.URL.VENDOR_LIST.METHOD, {
        index: 1,
        pageSize: 100000,
      }).subscribe((res: APIResponse) => {
        if (res.success) {
          this.vendorLists = res.data.vendors_list;
        }
      });
    }
  }
}
