import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ModalConfig } from '@models/modal-config';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { DownloadDataService } from '@services/download-data.service';
import { ApiConstants } from '@utils/api-constants';
import { APIResponse } from '@models/api-response-data';
import { exportDataTypes } from '@utils/constants';

@Component({
  selector: 'app-print-modal',
  templateUrl: './print-modal.component.html',
  styleUrls: ['./print-modal.component.scss']
})
export class PrintModalComponent implements OnInit {

  ngbModalRef!: NgbModalRef;
  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public exportInfo!: any
  @ViewChild('downloadData') private modalContent!: TemplateRef<PrintModalComponent>
  private modalRef!: NgbModalRef
  printForm!: FormGroup;
  submitted: boolean = false;
  type!: string;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.printForm = this.formBuilder.group({
      printType: ['customer-praxia', [Validators.required]],
      orderId: [],
      invoiceId: [],
      documentType: ['purchase-order']
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['exportInfo'].firstChange) {
      this.printForm.patchValue({
        orderId: changes['exportInfo'].currentValue.orderId,
        invoiceId: changes['exportInfo'].currentValue.invoiceId,
        documentType: changes['exportInfo'].currentValue.type,
      })
    }
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  get f() {
    return this.printForm.controls;
  }

  exportDataTo() {
    const dataPayload: any = {
      type: this.printForm.value.printType,
      documentType: this.printForm.value.documentType
    }
    if (this.printForm.value.invoiceId != '') {
      dataPayload.invoiceId = this.printForm.value.invoiceId
    }
    this.apiService.parseApiCall(ApiConstants.URL.DOWNLOAD_ORDER_DETAILS.URI + '/' + this.printForm.value.orderId,
      ApiConstants.URL.DOWNLOAD_ORDER_DETAILS.METHOD, dataPayload).subscribe((res: APIResponse) => {
        if (res.success) {
          window.open(ApiConstants.MEDIA_URL + '/orders/' + res.data.file_name, "_blank");
        }
      });
  }
}
