import { Component, OnInit, Input, ViewChild,  TemplateRef,Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalConfig } from '@models/modal-config';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-order-id-modal',
  templateUrl: './custom-order-id-modal.component.html',
  styleUrls: ['./custom-order-id-modal.component.scss']
})
export class CustomOrderIdModalComponent implements OnInit {
ngbModalRef!: NgbModalRef
orderIdUpdateForm!: FormGroup;
  @Input() public modalConfig!: ModalConfig
  private modalRef!: NgbModalRef
  @Output() success: EventEmitter<string> = new EventEmitter();
  @ViewChild('orderIdEdit') private modalContent!: TemplateRef<CustomOrderIdModalComponent>

  constructor( private modalService: NgbModal,private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.orderIdUpdateForm = this.formBuilder.group({
     
      optionalCustomOrderNo: ['']
    })
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }
  updateOrderId() {
    this.success.emit({
      ...this.orderIdUpdateForm.value,
     
    })
  }

}
