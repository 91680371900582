import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModalConfig } from '@models/modal-config';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { DownloadDataService } from '@services/download-data.service';
import { exportData } from '@utils/helper';

@Component({
  selector: 'app-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss']
})
export class DownloadModalComponent implements OnInit {

  ngbModalRef!: NgbModalRef;
  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public exportInfo!: any
  @ViewChild('downloadData') private modalContent!: TemplateRef<DownloadModalComponent>
  private modalRef!: NgbModalRef
  downloadForm!: FormGroup;
  submitted: boolean = false;
  type!: string;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private downloadDataService: DownloadDataService
  ) { }


  ngOnInit(): void {
    this.downloadForm = this.formBuilder.group({
      downloadType: ['customer-praxia', [Validators.required]],
      downloadFormat: ['excel', [Validators.required]]
    })
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  get f() {
    return this.downloadForm.controls;
  }

  exportDataTo() {
    let downloadData = [];
    const data = this.downloadDataService.grnDataList
    let fileName = ''
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const item: any = {
        'Item Name': element.itemDetails.customerItemDescription,
        'Date': element.orderDate,
        'HSN/SAC Code': element.hsnCode,
        'Batch': element.batch,
        'Expiry': element.expiry,
        'UOM': element.uom ? element.uom.toString() : '',
        'Quantity': element.quantity,
        'Offers': element.offer
      }
      if (this.downloadForm.value.downloadType === 'customer-praxia') {
        fileName = 'customer-praxia'
        item['Unit Rate'] = element.unitRate
        item['MRP'] = element.mrp
        item['Tax'] = element.taxPercent
        item['Amount'] = element.amount
        item['Tax Amount'] = element.taxableAmount
        item['Net Amount'] = element.netAmount
      } else {
        fileName = 'vendor-praxia'
        item['Unit Rate'] = element.vendorUnitRate
        item['MRP'] = element.vendorMRP
        item['Tax'] = element.vendorTaxPercent
        item['Amount'] = element.vendorAmount
        item['Tax Amount'] = element.vendorTaxableAmount
        item['Net Amount'] = element.vendorNetAmount
      }
      downloadData.push(item)
    }
    exportData(this.downloadForm.value.downloadFormat, downloadData, fileName);
    this.close()

  }
}
