import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ModalConfig } from '@models/modal-config';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';
import { APIResponse } from '@models/api-response-data';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-formulary-file-upload',
  templateUrl: './formulary-file-upload.component.html',
  styleUrls: ['./formulary-file-upload.component.scss']
})
export class FormularyFileUploadComponent implements OnInit {

  ngbModalRef!: NgbModalRef;
  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public fileData!: any
  @ViewChild('formularyFileUpload') private modalContent!: TemplateRef<FormularyFileUploadComponent>
  private modalRef!: NgbModalRef
  uploadForm!: FormGroup;
  submitted: boolean = false;
  customerDetails: any;
  customerCode!: string;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastrService,
    public auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.customerList();
    this.uploadForm = this.formBuilder.group({
      file: ['', [Validators.required]],
      uploadType: [this.fileData.uploadType, [Validators.required]],
      customerId: ['', [Validators.required]],
    })

  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['fileData'].firstChange) {
      this.uploadForm.patchValue({
        uploadType: changes['fileData'].currentValue.uploadType
      })
    }
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  get f() {
    return this.uploadForm.controls;
  }

  uploadFile() {
    if (this.uploadForm.valid) {
      const formData = new FormData();
      const file_form = this.uploadForm.value.file;
      const file = file_form.files[0];
      formData.append('uploadType', this.uploadForm.value.uploadType);
      formData.append('customerId', this.uploadForm.value.customerId);
      formData.append('file', file);
      this.apiService.parseApiCall(ApiConstants.URL.UPLOAD_DATA.URI,
        ApiConstants.URL.UPLOAD_DATA.METHOD, formData).subscribe((res: APIResponse) => {
          if (res.success) {
            this.toastService.success('Upload Success')
            this.success.emit()
            this.close();
          }
        });
    }
  }

  customerList() {
    this.apiService.parseApiCall(ApiConstants.URL.CUSTOMER_DETAILS.URI, ApiConstants.URL.CUSTOMER_DETAILS.METHOD, {
    }).subscribe((res: APIResponse) => {
      if (res.success) {
        this.customerDetails = res.data.customer_list;
      }
    });
  }
}
