import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ApiConstants } from '@utils/api-constants';
import { ApiService } from '@services/api-service.service';
import { APIResponse } from '@models/api-response-data';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { exportDataTypes } from '@utils/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { DataExportModalComponent } from '@components/data-export-modal/data-export-modal.component';
import * as moment from 'moment';


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  private destroy = new Subject();
  orderId!: string;
  orderDetails: any;
  itemList: any = [];
  itemGSTSplitList: any = [];
  userType: string = '';
  documentType: string = '';
  printType: any;
  exportDataType: any
  number: any;
  invoiceResponseData: any;
  taxText = 'GST';
  isIGST = false;
  invoiceId!: string;
  referenceNoandDate: any = '';

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe((query) => {
      this.userType = query['userType']
      this.printType = query['printType']
      this.documentType = query['documentType']
      this.invoiceId = query['invoiceId']
    })
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params) => {
      this.orderId = params['id'];
      this.getOrderDetails();
      if (this.documentType == 'invoice') {
        this.getVendorInvoiceDetails();
      }

    })
    console.log(this.invoiceId)
  }

  getOrderDetails() {
    this.apiService.parseApiCall(ApiConstants.URL.PRINT_ORDER_DETAILS.URI + '/' + this.orderId,
      ApiConstants.URL.PRINT_ORDER_DETAILS.METHOD, {}).subscribe((res: APIResponse) => {
        if (res.success) {
          this.orderDetails = res.data.order_details
          if (this.orderDetails.isDropShipMent) {
            if (this.userType === 'hospital-admin') {
              if (this.orderDetails.customer && this.orderDetails.customer.billingAddress && this.orderDetails.customer.billingAddress.state != 'Kerala') {
                this.taxText = 'IGST';
                this.isIGST = true
              }
            }
            if (this.userType === 'vendor') {
              if (this.documentType === 'purchase-order') {
                if (this.orderDetails.vendor && this.orderDetails.vendor.billingAddress && this.orderDetails.vendor.billingAddress.state != 'Kerala') {
                  this.taxText = 'IGST';
                  this.isIGST = true
                }
              } else {
                if (this.orderDetails.customer && this.orderDetails.customer.billingAddress && this.orderDetails.customer.billingAddress.state != 'Kerala') {
                  this.taxText = 'IGST';
                  this.isIGST = true
                }
              }
            }
            if (this.userType === 'super-admin') {
              if (this.printType === 'vendor-praxia') {
                if (this.orderDetails.vendor && this.orderDetails.vendor.billingAddress && this.orderDetails.vendor.billingAddress.state != 'Kerala') {
                  this.taxText = 'IGST';
                  this.isIGST = true
                }
              }
              if (this.printType === 'customer-praxia') {
                if (this.orderDetails.customer && this.orderDetails.customer.billingAddress && this.orderDetails.customer.billingAddress.state != 'Kerala') {
                  this.taxText = 'IGST';
                  this.isIGST = true
                }
              }
            }
          } else {
            if (this.orderDetails.vendor && this.orderDetails.vendor.billingAddress && this.orderDetails.customer && this.orderDetails.customer.billingAddress && this.orderDetails.vendor.billingAddress.state != this.orderDetails.customer.billingAddress.state) {
              this.taxText = 'IGST';
              this.isIGST = true
            }
          }
          const orderAmount = {
            amount: 0,
            netAmount: 0,
            taxAmount: 0,
            vendorAmount: 0,
            vendorTaxableAmount: 0,
            vendorNetAmount: 0
          }
          for (let index = 0; index < this.orderDetails.orders_items.length; index++) {
            const element = this.orderDetails.orders_items[index];
            if (!['pending', 'rejected'].includes(element.status)) {
              if (element.invoiceIds && element.invoiceIds.length > 0) {
                if (this.invoiceId && this.invoiceId.toString() != 'undefined' && this.documentType == 'invoice') {
                  if (element.invoiceIds.includes(this.invoiceId)) {
                    const invoiceListItems = element.invoiceItems.filter((e: any) => { return e.invoiceId.toString() === this.invoiceId.toString() })
                    for (let i = 0; i < invoiceListItems.length; i++) {
                      const item = invoiceListItems[i];
                      item.taxableAmount = item.taxAmount
                      item.vendorTaxableAmount = item.vendorAmount
                      this.itemList.push({ ...item });
                      orderAmount.amount = orderAmount.amount + item.amount
                      orderAmount.netAmount = orderAmount.netAmount + item.netAmount
                      orderAmount.taxAmount = orderAmount.taxAmount + item.taxAmount

                      orderAmount.vendorAmount = orderAmount.vendorAmount + item.vendorAmount
                      orderAmount.vendorNetAmount = orderAmount.vendorNetAmount + item.vendorNetAmount
                      orderAmount.vendorTaxableAmount = orderAmount.vendorTaxableAmount + item.vendorTaxAmount

                      const itemIndex = this.itemGSTSplitList.findIndex((e: any) => { return e.hsnCode == item.hsnCode && e.taxPercent === item.taxPercent });
                      if (itemIndex > -1) {
                        this.itemGSTSplitList[itemIndex]['amount'] = this.itemGSTSplitList[itemIndex]['amount'] + item.amount
                        this.itemGSTSplitList[itemIndex]['taxableAmount'] = this.itemGSTSplitList[itemIndex]['taxableAmount'] + item.taxableAmount
                        this.itemGSTSplitList[itemIndex]['vendorAmount'] = this.itemGSTSplitList[itemIndex]['vendorAmount'] + element.vendorAmount
                        this.itemGSTSplitList[itemIndex]['vendorTaxableAmount'] = this.itemGSTSplitList[itemIndex]['vendorTaxableAmount'] + element.vendorTaxableAmount
                      } else {
                        this.itemGSTSplitList.push(item)
                      }
                    }
                  }
                } else {
                  this.itemList.push({ ...element });
                  const itemIndex = this.itemGSTSplitList.findIndex((e: any) => { return e.hsnCode == element.hsnCode && e.taxPercent === element.taxPercent });
                  if (itemIndex > -1) {
                    this.itemGSTSplitList[itemIndex]['amount'] = this.itemGSTSplitList[itemIndex]['amount'] + element.amount
                    this.itemGSTSplitList[itemIndex]['taxableAmount'] = this.itemGSTSplitList[itemIndex]['taxableAmount'] + element.taxableAmount
                    this.itemGSTSplitList[itemIndex]['vendorAmount'] = this.itemGSTSplitList[itemIndex]['vendorAmount'] + element.vendorAmount
                    this.itemGSTSplitList[itemIndex]['vendorTaxableAmount'] = this.itemGSTSplitList[itemIndex]['vendorTaxableAmount'] + element.vendorTaxableAmount
                  } else {
                    this.itemGSTSplitList.push(element)
                  }
                }
              } else if (this.documentType != 'invoice') {
                this.itemList.push({ ...element });
                const itemIndex = this.itemGSTSplitList.findIndex((e: any) => { return e.hsnCode == element.hsnCode && e.taxPercent === element.taxPercent });
                if (itemIndex > -1) {
                  this.itemGSTSplitList[itemIndex]['amount'] = this.itemGSTSplitList[itemIndex]['amount'] + element.amount
                  this.itemGSTSplitList[itemIndex]['taxableAmount'] = this.itemGSTSplitList[itemIndex]['taxableAmount'] + element.taxableAmount
                  this.itemGSTSplitList[itemIndex]['vendorAmount'] = this.itemGSTSplitList[itemIndex]['vendorAmount'] + element.vendorAmount
                  this.itemGSTSplitList[itemIndex]['vendorTaxableAmount'] = this.itemGSTSplitList[itemIndex]['vendorTaxableAmount'] + element.vendorTaxableAmount
                } else {
                  this.itemGSTSplitList.push(element)
                }
              }
            }
          }
          if (this.invoiceId && this.invoiceId.toString() != 'undefined') {
            this.orderDetails.totalItemPrice = orderAmount.amount;
            this.orderDetails.totalAmount = orderAmount.netAmount;
            this.orderDetails.totalTax = orderAmount.taxAmount;
            this.orderDetails.vendorTotalItemPrice = orderAmount.vendorAmount;
            this.orderDetails.vendorTotalAmount = orderAmount.vendorNetAmount;
            this.orderDetails.vendorTotalTax = orderAmount.vendorTaxableAmount;
          }
          console.log('itemList', this.itemList)
          console.log('itemGSTSplitList', this.itemGSTSplitList)
          console.log(this.orderDetails)
        }
      });
  }

  getVendorInvoiceDetails() {
    let query: any = {}
    if (this.invoiceId && this.invoiceId.toString() != 'undefined') {
      query.invoiceId = this.invoiceId
    }
    this.apiService.parseApiCall(ApiConstants.URL.LATEST_UPLOADED_INVOICE_ID.URI + '/' + this.orderId + '/' + 'get-latest-invoice',
      ApiConstants.URL.LATEST_UPLOADED_INVOICE_ID.METHOD, query).subscribe((res: APIResponse) => {
        if (res) {
          const invoiceResponse = res;
          this.invoiceResponseData = invoiceResponse;
          if (this.invoiceResponseData.vendorInvoiceRefNo && this.invoiceResponseData.vendorInvoiceRefDate) {
            this.referenceNoandDate = this.invoiceResponseData.vendorInvoiceRefNo + ' / ' + moment(this.invoiceResponseData.vendorInvoiceRefDate).format('DD-MM-YYYY')
          }
          console.log(res)
        }
      }

      );

    // this.listOrderInvoices();
  }



  // convertNumberToWords(number: number): string {
  //   const words: string[] = [
  //     'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
  //     'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
  //   ];

  //   const tens: string[] = [
  //     '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
  //   ];

  //   const convertNumberToWordsRecursive = (num: number): string => {
  //     if (num < 20) {
  //       return words[num];
  //     } else if (num < 100) {
  //       const tensDigit: number = Math.floor(num / 10);
  //       const onesDigit: number = num % 10;
  //       const tensWord: string = tens[tensDigit];
  //       const onesWord: string = onesDigit !== 0 ? words[onesDigit] : '';
  //       return tensWord + ' ' + onesWord;
  //     } else if (num < 1000) {
  //       const hundredsDigit: number = Math.floor(num / 100);
  //       const tensAndOnes: number = num % 100;
  //       const hundredsWord: string = words[hundredsDigit] + ' Hundred';
  //       const tensAndOnesWord: string = tensAndOnes !== 0 ? convertNumberToWordsRecursive(tensAndOnes) : '';
  //       return hundredsWord + ' ' + tensAndOnesWord;
  //     } else if (num < 100000) {
  //       const thousandsDigit: number = Math.floor(num / 1000);
  //       const remainingNum: number = num % 1000;
  //       const thousandsWord: string = convertNumberToWordsRecursive(thousandsDigit) + ' Thousand';
  //       const remainingNumWord: string = remainingNum !== 0 ? convertNumberToWordsRecursive(remainingNum) : '';
  //       return thousandsWord + ' ' + remainingNumWord;
  //     } else if (num < 10000000) {
  //       const lakhsDigit: number = Math.floor(num / 100000);
  //       const remainingNum: number = num % 100000;
  //       const lakhsWord: string = convertNumberToWordsRecursive(lakhsDigit) + ' Lakh';
  //       const remainingNumWord: string = remainingNum !== 0 ? convertNumberToWordsRecursive(remainingNum) : '';
  //       return lakhsWord + ' ' + remainingNumWord;
  //     } else {
  //       const croresDigit: number = Math.floor(num / 10000000);
  //       const remainingNum: number = num % 10000000;
  //       const croresWord: string = convertNumberToWordsRecursive(croresDigit) + ' Crore';
  //       const remainingNumWord: string = remainingNum !== 0 ? convertNumberToWordsRecursive(remainingNum) : '';
  //       return croresWord + ' ' + remainingNumWord;
  //     }
  //   };

  //   const wholeNumber: number = Math.floor(number);
  //   const fractionalPart: number = Math.floor((number % 1) * 100); // Convert fractional part to two-digit number
  //   const wordsWholeNumber: string = convertNumberToWordsRecursive(wholeNumber);
  //   const wordsFractionalPart: string = convertNumberToWordsRecursive(fractionalPart);

  //   let result: string = wordsWholeNumber.replace(/ zero/g, '');

  //   if (wordsFractionalPart !== '') {
  //     result;
  //   }
  //   return result;
  // }
  convertNumberToWords(number: number): string {
    const words: string[] = [
      'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
      'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];

    const tens: string[] = [
      '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];

    const convertNumberToWordsRecursive = (num: number): string => {
      if (num < 20) {
        return words[num];
      } else if (num < 100) {
        const tensDigit: number = Math.floor(num / 10);
        const onesDigit: number = num % 10;
        const tensWord: string = tens[tensDigit];
        const onesWord: string = onesDigit !== 0 ? words[onesDigit] : '';
        return tensWord + (onesWord ? ' ' + onesWord : '');
      } else if (num < 1000) {
        const hundredsDigit: number = Math.floor(num / 100);
        const tensAndOnes: number = num % 100;
        const hundredsWord: string = words[hundredsDigit] + ' Hundred';
        const tensAndOnesWord: string = tensAndOnes !== 0 ? ' ' + convertNumberToWordsRecursive(tensAndOnes) : '';
        return hundredsWord + tensAndOnesWord;
      } else if (num < 100000) {
        const thousandsDigit: number = Math.floor(num / 1000);
        const remainingNum: number = num % 1000;
        const thousandsWord: string = convertNumberToWordsRecursive(thousandsDigit) + ' Thousand';
        const remainingNumWord: string = remainingNum !== 0 ? ' ' + convertNumberToWordsRecursive(remainingNum) : '';
        return thousandsWord + remainingNumWord;
      } else if (num < 10000000) {
        const lakhsDigit: number = Math.floor(num / 100000);
        const remainingNum: number = num % 100000;
        const lakhsWord: string = convertNumberToWordsRecursive(lakhsDigit) + ' Lakh';
        const remainingNumWord: string = remainingNum !== 0 ? ' ' + convertNumberToWordsRecursive(remainingNum) : '';
        return lakhsWord + remainingNumWord;
      } else {
        const croresDigit: number = Math.floor(num / 10000000);
        const remainingNum: number = num % 10000000;
        const croresWord: string = convertNumberToWordsRecursive(croresDigit) + ' Crore';
        const remainingNumWord: string = remainingNum !== 0 ? ' ' + convertNumberToWordsRecursive(remainingNum) : '';
        return croresWord + remainingNumWord;
      }
    };

    const wholeNumber: number = Math.floor(number);
    const wordsWholeNumber: string = convertNumberToWordsRecursive(wholeNumber);

    return wordsWholeNumber;
}

  roundAngularValue(value: number) {
    var roundedValue = Math.floor(value);

    if (value - roundedValue >= 0.5) {
      roundedValue += 1;
    }

    return roundedValue;
  }
}
