import { environment } from 'src/environments/environment';


export const ApiConstants = {
    MEDIA_URL: `${environment.media_path}`,
    URL: {
        LOGIN: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/auth/login`
        },
        FORGOTPASSWORD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/auth/forgotpassword`
        },
        RESETPASSWORD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/auth/resetpassword`
        },
        VERIFICATION: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/customers/verifyaccount`
        },
        REGISTER: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/auth/register`
        },
        CUSTOMER_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers`
        },

        CUSTOMER_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/customers`
        },
        CUSTOMER_DELETE: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/customers`
        },
        CUSTOMER_UPDATE: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/customers`
        },
        CUSTOMER_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers`
        },
        CUSTOMER_STORE_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/stores`
        },
        VENDOR_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/vendor`
        },
        VENDOR_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/vendor`
        },
        VENDOR_UPDATE: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/vendor`
        },
        VENDOR_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/vendor`
        },
        CUSTOMER_STORE_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/stores`
        },
        CUSTOMER_STORE_UPDATE: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/customers/store`
        },
        ITEM_MASTER_DATA: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/data/itemmaster`
        },
        CUSTOMER_ITEM_MASTER_DATA: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/data/itemmaster`
        },
        CUSTOMER_ORDERS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/orders`
        },
        CUSTOMER_STORE_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/customers/store`
        },
        VENDOR_ORDER_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order`
        },
        CUSTOMER_STORE_ADD_USER: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/customers/store/user`
        },
        CUSTOMER_STORE_USER_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/store`
        },
        CUSTOMER_STORE_UPDATE_USER: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/customers/store/user`
        },
        CUSTOMER_UPDATE_ITEM_MASTER_DATA: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/data/itemmaster`
        },
        CUSTOMER_USERS_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/users`
        },
        ITEM_MASTER_UPDATE: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/data/itemmaster`
        },
        ITEM_MASTER_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/data/itemmaster`
        },
        ITEM_MASTER_DELETE: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/data/itemmaster`
        },
        VENDOR_ORDER_UPDATE_QUANTITY: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/order`
        },
        VENDOR_ORDER_REVIEW_SUBMIT: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/order`
        },
        CUSTOMER_ORDER_STATUS_UPDATE: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/order`
        },
        VENDOR_ORDER_INVOICE_UPLOAD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/order`
        },
        FORMULARY_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/formulary`
        },
        FORMULARY_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/formulary`
        },
        FORMULARY_DELETE: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/formulary`
        },
        FORMULARY_UPDATE: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/formulary`
        },
        LOGS_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/logs`
        },
        NOTIFICATION_UNREAD_COUNT: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/notification/unread-count`
        },
        NOTIFICATION_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/notification`
        },
        NOTIFICATION_MARK_AS_READ: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/notification`
        },
        NOTIFICATION_DELETE: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/notification`
        },
        ORDER_INVOICE_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order`
        },
        CUSTOMER_ORDER_DISCREPANCY: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/order/discrepancy`
        },
        CUSTOMER_VENDOR_PREFERENCE_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/vendor/preferences`
        },
        UPLOAD_DATA: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/data/upload`
        },
        GET_PROFILE_INFO: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/user/me`
        },
        UPDATE_PROFILE_INFO: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/user/me`
        },
        UPDATE_PROFILE_PASSWORD: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/user/me/password`
        },
        CUSTOMER_DAILY_STOCK_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/data/history/stocks`
        },
        CUSTOMER_SALES_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/data/history/sales`
        },
        CUSTOMER_PURCHASE_ORDER_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/data/history/purchaseorder`
        },
        FILE_UPLOAD_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/data/history/files`
        },
        FILE_DOWNLOAD: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/data/history/files/download`
        },
        VENDOR_DROPDOWN_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/vendor/selectlist`
        },
        CUSTOMER_VENDOR_PREFERENCE_UPDATE: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/customers/vendor/preferences`
        },
        CUSTOMER_ORDERS_ITEMS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/orderItems`
        },
        VENDOR_ORDER_STATISTICS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order/stats`
        },
        CUSTOM_HEADERS_FILTER: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customHeaderMap/system-header-list`
        },
        CUSTOM_HEADERS_EDIT: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/customHeaderMap`
        },
        CUSTOM_HEADERS_DELETE: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/customHeaderMap`
        },
        CUSTOM_HEADERS_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/customHeaderMap`
        },
        CUSTOM_HEADERS_GET: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customHeaderMap`
        },
        CUSTOM_HEADERS_CUSTOMER_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customHeaderMap/customer-name-list`
        },
        CUSTOMER_USERS_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/customers/users`
        },
        CUSTOMER_ADMIN_USERS_REGISTER: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/auth/register`
        },
        CUSTOMER_ADMIN_USERS_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/user/me/admin`
        },
        CUSTOMER_ADMIN_USERS_DELETE: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/user/me/admin`
        },
        CUSTOMER_ADMIN_USERS_EDIT: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/user/me/admin`
        },
        GENERATE_ORDER: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/order/generate`
        },
        CUSTOMER_STOCKS_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/data/stocks`
        },
        CUSTOMER_STOCKS_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/data/stocks`
        },
        UPDATE_STOCKS_DETAILS: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/customers/data/stocks`
        },
        DASHBOARD_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/dashboard`
        },
        STORE_DAILY_STOCK_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/stores/data/history/stocks`
        },
        STORE_SALES_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/stores/data/history/sales`
        },
        STORE_PURCHASE_ORDER_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/stores/data/history/purchaseorder`
        },
        VENDOR_USERS_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/vendor/users`
        },
        CUSTOMER_ORDER_ITEM_STATUS_UPDATE: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/order`
        },
        TRANSFER_STOCK: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/stores/data/stocks/transfer`
        },
        SUPERADMIN_DASHBOARD: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/stats`
        },
        RATE_CONTRACT_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/ratecontract`
        },
        RATE_CONTRACT_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/ratecontract`
        },
        RATE_CONTRACT_DELETE: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/ratecontract`
        },
        RATE_CONTRACT_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/ratecontract`
        },
        RATE_CONTRACT_UPDATE: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/ratecontract`
        },
        ORDER_PRAXIA_CONTROLLED_ITEMS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order`
        },
        CUSTOMER_USERS_DELETE: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/customers/users`
        },
        CUSTOMER_ORDER_GRN_ENTRY: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order`
        },
        ORDER_ITEM_RETURNS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order/return`
        },
        ORDER_ITEM_RETURNS_UPDATE_STATUS: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/order/return/returnItemId/status/bulkupdate`
        },
        ORDER: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/order`
        },
        MESSAGE_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/message`
        },
        ADD_MESSAGE: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/message`
        },
        CUSTOMERS_SELECTLIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/selectlist`
        },
        PRINT_ORDER_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order/print`
        },
        DOWNLOAD_ORDER_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order/download`
        },
        VENDOR_ITEM_MASTER_DATA: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/vendor/data/itemmaster`
        },
        CUSTOMER_ADD_ITEM_MASTER_DATA: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/customers/data/itemmaster`
        },
        CUSTOMER_DELETE_ITEM_MASTER_DATA: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/customers/data/itemmaster`
        },
        GET_PREFERENCE_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/system/prefrences`
        },
        UPDATE_PREFERENCE_DETAILS: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/system/prefrences`
        },
        POST_VENDOR_RATING: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/vendor/rating`
        },
        LIST_VENDOR_RATING: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/vendor/rating`
        },
        STORE_DROPDOWN_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/stores/selectlist`
        },
        CUSTOMER_PO_ITEM: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/customers/item/purchaseorder`
        },
        DELETE_ORDER: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/order`
        },
        DELETE_ORDER_BULK: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/order/bulk`
        },
        GET_INVOICE_ORDER_DISCREPANCY: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/invoices/discrepancy`
        },
        LIST_VENDOR_DELIVERY_SCHEDULE: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/vendor/deliveryschedule`
        },
        ADD_VENDOR_DELIVERY_SCHEDULE: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/vendor/deliveryschedule`
        },
        ADD_VENDOR_ITEM_MASTER_DATA: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/vendor/data/itemmaster`
        },
        ORDER_ITEM_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order/orderitems`
        },
        ADD_VENDOR_ITEM_LEAD_TIME: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/vendor/leadtime`
        },
        LIST_VENDOR_ITEM_LEAD_TIME: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/vendor/leadtime`
        },
        UPDATE_VENDOR_ITEM_LEAD_TIME: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/vendor/leadtime`
        },
        DELETE_VENDOR_ITEM_LEAD_TIME: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/vendor/leadtime`
        },

        LIST_ORDER_DEBIT_NOTE: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order`
        },
        LIST_ORDER_CREDIT_NOTE: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order`
        },
        LATEST_UPLOADED_INVOICE_ID: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/order`
        },
        ORDER_ITEM_RETURN_BULK: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/order/return/bulk`
        },
        CUSTOMER_ADMIN_USERS_PERMISSION: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/user/me/admin/permission`
        },
        GET_REPORT_DATA: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/reports`
        },
        VENDOR_RATE_CONTRACT_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/ratecontract/vendor`
        },
        VENDOR_RATE_CONTRACT_ADD: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/ratecontract/vendor`
        },
        VENDOR_RATE_CONTRACT_DELETE: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/ratecontract/vendor`
        },
        VENDOR_RATE_CONTRACT_DETAILS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/ratecontract/vendor`
        },
        VENDOR_RATE_CONTRACT_UPDATE: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/ratecontract/vendor`
        },
        PRAXIA_ORDERS: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/praxia/orders`
        },
        GET_WORK_FLOW: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/workflow`
        },
        ADD_WORK_FLOW: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/workflow`
        },
        DELETE_WORK_FLOW: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/workflow`
        },
        UPDATE_WORK_FLOW: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/workflow`
        },
        ASSIGN_WORK_FLOW: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/workflow/assign`
        },
        GET_ASSIGN_WORK_FLOW: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/workflow/assign`
        },
        DELETE_ASSIGN_WORK_FLOW: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/workflow/assign`
        },
        GET_ACCESS_APPROVE_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/workflow/approvelist`
        },
        GET_ACCESS_REQUEST_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/workflow/requestlist`
        },
        GET_ACCESS_REVIEW_LIST: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/workflow/reviewlist`
        },
        ACCESS_REVIEW_DONE: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/workflow/review/done`
        },
        ACCESS_APPROVE_DONE: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/workflow/approve/done`
        },
        UPDATE_ORDER_ITEM: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/order-items`
        },
        DELETE_ORDER_ITEM: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/order-items`
        },
        CLOSE_ORDER_ITEM: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/order-items`
        },
        AUTO_CALCULATE_ROL_ROQ: {
            METHOD: 'GET',
            URI: `${environment.api_endpoint}/customers/auto-calc-rol-roqs`
        },
        UPDATE_ORDER_INVOCIE_DISCREPANCY: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/invoices/discrepancy`
        },
        DLETE_ORDER_INVOCIE_DISCREPANCY: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/invoices/discrepancy`
        },
        DELETE_VENDOR_ITEM_MASTER_DATA: {
            METHOD: 'DELETE',
            URI: `${environment.api_endpoint}/vendor/data/itemmaster`
        },
        UPDATE_VENDOR_ITEM_MASTER_DATA: {
            METHOD: 'PUT',
            URI: `${environment.api_endpoint}/vendor/data/itemmaster`
        },
        CUSTOMER_ITEM_MASTER_MAPPING: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/customers/data/itemmaster/mapping`
        },
        ADD_VENDOR_ITEM_MASTER_MAPPING: {
            METHOD: 'POST',
            URI: `${environment.api_endpoint}/vendor/data/itemmaster/mapping`
        },
    }
}