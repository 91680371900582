import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ModalConfig } from '@models/modal-config';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api-service.service';
import { ApiConstants } from '@utils/api-constants';
import { APIResponse } from '@models/api-response-data';
import { ToastrService } from 'ngx-toastr';
import { exportData } from '@utils/helper';
import { DownloadDataService } from '@services/download-data.service';
import { exportDataTypes } from '@utils/constants';

@Component({
  selector: 'app-data-export-modal',
  templateUrl: './data-export-modal.component.html',
  styleUrls: ['./data-export-modal.component.scss']
})
export class DataExportModalComponent implements OnInit {

  ngbModalRef!: NgbModalRef;
  @Output() success: EventEmitter<boolean> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public exportInfo!: any
  @Input() public invoiceInfo!: any
  @ViewChild('exportData') private modalContent!: TemplateRef<DataExportModalComponent>
  private modalRef!: NgbModalRef
  exportForm!: FormGroup;
  invoiceForm!: FormGroup;
  submitted: boolean = false;
  pdfType: boolean = false;
  type!: string;
  invoiceInfoType: string = '';
  invoiceDetails: any
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastrService,
    private downloadDataService: DownloadDataService
  ) { }

  ngOnInit(): void {
    this.exportForm = this.formBuilder.group({
      downloadType: ['pdf', [Validators.required]]
    })
    this.type = this.exportInfo.type
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.type = this.exportInfo.type
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    this.pdfType = false
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  get f() {
    return this.exportForm.controls;
  }

  exportDataTo() {
    let data = [];
    let file_name = 'sample'
    switch (this.type) {
      case exportDataTypes.FORMULARY_DATA:
        data = this.downloadDataService.formularyData
        file_name = 'formualry_data'
        break;
      case exportDataTypes.RATE_CONTRACT_DATA:
        data = this.downloadDataService.rateContractData
        file_name = 'rate_contract_data'
        break;
      case exportDataTypes.MASTER_PRODUCT_LIST_DATA:
        data = this.downloadDataService.masterData
        file_name = 'master_data'
        break;
      case exportDataTypes.CUSTOMER_ITEM_LIST:
        data = this.downloadDataService.customerMasterItems
        file_name = 'customer_master_data'
        break;
      case exportDataTypes.VENDOR_LIST:
        data = this.downloadDataService.vendorList
        file_name = 'vendor_list'
        break;
      case exportDataTypes.CUSTOMER_LIST:
        data = this.downloadDataService.customerList
        file_name = 'customer_list'
        break;
      case exportDataTypes.GRN_DATA:
        this.pdfType = false
        data = this.downloadDataService.grnDataList
        file_name = 'grn_data'
        break;
      case exportDataTypes.ORDER_DETAILS:
        this.pdfType = false
        data = this.downloadDataService.grnDataList
        file_name = 'PurchaseOrder'
        break;
      case exportDataTypes.LOG_LIST:
        data = this.downloadDataService.logs
        file_name = 'logs'
        break;
      case exportDataTypes.CUSTOMER_ORDER_LIST:
        data = this.downloadDataService.customerOrderList
        file_name = 'orders'
        break;
      case exportDataTypes.CUSTOMER_PRODUCT_LIST:
        data = this.downloadDataService.customerProductList
        file_name = 'products'
        break;
      case exportDataTypes.CUSTOMER_STOCK_LIST:
        data = this.downloadDataService.customerStockList
        file_name = 'stocks'
        break;
      case exportDataTypes.CUSTOMER_STORE_LIST:
        data = this.downloadDataService.customerStoreList
        file_name = 'stores'
        break;
      case exportDataTypes.CUSTOMER_VENDOR_LIST:
        data = this.downloadDataService.customerVendorList
        file_name = 'vendors'
        break;
      case exportDataTypes.CUSTOMER_VENDOR_PREFERENCE_LIST:
        data = this.downloadDataService.customerVendorPreferenceList
        file_name = 'vendor preferences'
        break;
      case exportDataTypes.CUSTOMER_DAILYSTOCK_LIST:
        data = this.downloadDataService.customerDailyStockList
        file_name = 'daily stocks'
        break;
      case exportDataTypes.CUSTOMER_PURCHASEORDER_LIST:
        data = this.downloadDataService.customerPurchaseOrderList
        file_name = 'purchase orders'
        break;
      case exportDataTypes.CUSTOMER_SALES_LIST:
        data = this.downloadDataService.customerSalesList
        file_name = 'sales'
        break;
      case exportDataTypes.CUSTOMER_ORDERITEM_LIST:
        data = this.downloadDataService.customerOrderItemList
        file_name = 'order items'
        break;
      case exportDataTypes.VENDOR_ORDERS_LIST:
        data = this.downloadDataService.vendorOrdersList
        file_name = 'orders'
        break;
      case exportDataTypes.STORE_VENDOR_LIST:
        data = this.downloadDataService.storeVendorList
        file_name = 'vendors'
        break;
      case exportDataTypes.STORE_ORDER_LIST:
        data = this.downloadDataService.storeOrderList
        file_name = 'orders'
        break;
      case exportDataTypes.VENDOR_ITEM_LIST:
        data = this.downloadDataService.vendorMasterItems
        file_name = 'vendor_items'
        break;
      case exportDataTypes.VENDOR_PRODUCT_LIST:
        data = this.downloadDataService.vendorProductList
        file_name = 'vendor_items'
        break;
      case exportDataTypes.REPORT_DATA:
        data = this.downloadDataService.reportData
        file_name = 'report_data'
        break;
      case exportDataTypes.PRAXIA_TO_HOSPITAL_INVOICE:
        if (this.exportForm.value.downloadType == 'excel') {
          this.pdfType = false
          data = this.downloadDataService.PraxiaToHspitalList
        }
        else {
          this.pdfType = true
        }
        file_name = 'invoice'
        break;
      default:
        break;
    }
    if (this.pdfType == true) {
      this.downloadOrder()
    }
    else {
      exportData(this.exportForm.value.downloadType, data, file_name);
    }


    this.close()
  }
  downloadOrder() {
    this.apiService.parseApiCall(ApiConstants.URL.DOWNLOAD_ORDER_DETAILS.URI + '/' + this.exportInfo.orderId,
      ApiConstants.URL.DOWNLOAD_ORDER_DETAILS.METHOD, { documentType: 'invoice', invoiceId: this.exportInfo.invoiceId }).subscribe((res: APIResponse) => {
        if (res.success) {
          window.open(ApiConstants.MEDIA_URL + '/orders/' + res.data.file_name, "_blank");
        }
      });
  }
}
