import { Component, OnInit,Input, TemplateRef,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { APIResponse } from '@models/api-response-data';
import { ModalConfig } from '@models/modal-config';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api-service.service';
import { AuthenticationService } from '@services/authentication.service';
import { ApiConstants } from '@utils/api-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-leadtime',
  templateUrl: './leadtime.component.html',
  styleUrls: ['./leadtime.component.scss']
})
export class LeadtimeComponent implements OnInit {
  ngbModalRef!: NgbModalRef;
  @Input() public leadTimeId: any;
  leadTimeForm!: FormGroup;
  @Input() public modalConfig!: ModalConfig;
  private modalRef!: NgbModalRef
  @ViewChild('leadTime') private modalContent!: TemplateRef<LeadtimeComponent>

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastrService,
    public auth: AuthenticationService,
    private router:Router) { }

  ngOnInit(): void {
    console.log(this.leadTimeId)
    this.leadTimeForm = this.formBuilder.group({
      leadTime: ['', [Validators.required]],
      id: [''],
      
    })

  }
  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }
  editVendorLeadTime() {
    console.log(this.leadTimeForm.value)
    console.log(this.leadTimeId)
    const payload = {
      id:  this.leadTimeId,
      leadTime: this.leadTimeForm.value.leadTime
    }
    if (this.leadTimeForm.valid) {
      this.apiService.parseApiCall(ApiConstants.URL.UPDATE_VENDOR_ITEM_LEAD_TIME.URI, ApiConstants.URL.UPDATE_VENDOR_ITEM_LEAD_TIME.METHOD, payload).subscribe((res: APIResponse) => {
        if (res.success) {
          this.toastService.success(res.message)
          this.router.navigate([`/customer/leadtime`]);
        }
      })
    }
  }


}
