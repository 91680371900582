import { RouteInfo } from '@models/route-info-data';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/suadmin/dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Orders',
    icon: 'add_shopping_cart',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/suadmin/orders-management',
        title: 'Order List',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '/suadmin/products-management',
    title: 'Products',
    icon: 'add_shopping_cart',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/suadmin/customer-management',
    title: 'Customers',
    icon: 'store',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/suadmin/customer-management',
        title: 'Customer List',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/suadmin/customer-management/item-mapping',
        title: 'Item Mapping',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/suadmin/customer-management/custom-mapping',
        title: 'Custom Mapping',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/suadmin/formulary-management',
    title: 'Formulary',
    icon: 'stars',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Rate Contracts',
    icon: 'stars',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/suadmin/rate-contracts',
        title: 'Praxia - Customer',
        icon: 'stars',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/suadmin/rate-contracts/vendor',
        title: 'Praxia - Vendor',
        icon: 'stars',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Vendors',
    icon: 'spa',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/suadmin/vendor-management',
        title: 'Vendors List',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/suadmin/vendor-management/item-mapping',
        title: 'Item Mapping',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/suadmin/vendor-management/invoice-mapping',
        title: 'Invoice Mapping',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '/suadmin/reports',
    title: 'Reports',
    icon: 'equalizer',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/suadmin/logs',
    title: 'Logs',
    icon: 'description',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/suadmin/messages',
    title: 'Messages',
    icon: 'message',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Settings',
    icon: 'settings',
    class: '',
    badge: '',
    role: 'super-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/suadmin/settings/profile',
        title: 'Profile',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/suadmin/settings/users',
        title: 'Users',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/suadmin/settings/system-preferences',
        title: 'Preferences',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ]
  },
  //############ vendor #######################
  // {
  //   path: '/vendor/dashboard',
  //   title: 'Dashboard',
  //   icon: 'dashboard',
  //   class: '',
  //   badge: '',
  //   role: 'vendor',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  // },
  {
    path: '',
    title: 'Orders',
    icon: 'add_shopping_cart',
    class: '',
    badge: '',
    role: 'vendor',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/vendor/order',
        title: 'Order List',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/vendor/order/items/returns',
        title: 'Items Returns',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },

  // Customer Routes
  {
    path: '/customer/dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Orders',
    icon: 'shopping_basket',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/customer/orders-management',
        title: 'Order List',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/orders-management/items',
        title: 'Order Items',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/orders-management/items/returns',
        title: 'Items Returns',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '/customer/products-management',
    title: 'Products',
    icon: 'add_shopping_cart',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/customer/stocks-management',
    title: 'Stocks',
    icon: 'add_shopping_cart',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/customer/stores-management',
    title: 'Stores',
    icon: 'store',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Vendors',
    icon: 'spa',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/customer/vendor-management',
        title: 'List',
        icon: 'spa',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/vendor-preferences',
        title: 'Preferences',
        icon: 'spa',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/vendor-items',
        title: 'Items',
        icon: 'spa',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/vendor-delivery-schedules',
        title: 'Delivery Schedule',
        icon: 'spa',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/leadtime',
        title: 'Lead Time',
        icon: 'spa',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Data Uploads',
    icon: 'cloud_upload',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/customer/data-uploads/daily-stocks',
        title: 'Daily Stocks',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/data-uploads/purchase-orders',
        title: 'Purchase Order',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/data-uploads/sales-datas',
        title: 'Sales',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Access Approval',
    icon: 'published_with_changes',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/customer/access-approval/request',
        title: 'Request',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/access-approval/review',
        title: 'Review',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/access-approval/approve',
        title: 'Approve',
        icon: '',
        class: '',
        badge: '',
        role: 'super-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '/customer/messages',
    title: 'Messages',
    icon: 'message',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Settings',
    icon: 'settings',
    class: '',
    badge: '',
    role: 'hospital-admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/customer/settings/account',
        title: 'Account',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/settings/profile',
        title: 'Profile',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/settings/users',
        title: 'Users',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/customer/settings/work-flow',
        title: 'Work Flow',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ]
  },
  // Customer Store User Routes
  {
    path: '/store/dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    class: '',
    badge: '',
    role: 'hospital-user',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/store/store',
    title: 'Orders',
    icon: 'add_shopping_cart',
    class: '',
    badge: '',
    role: 'hospital-user',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },

  {
    path: '/customer/products',
    title: 'Products',
    icon: 'add_shopping_cart',
    class: '',
    badge: '',
    role: 'hospital-users',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/store/ItemSubscriptions',
    title: 'Item Subscriptions',
    icon: 'graphic_eq',
    class: '',
    badge: '',
    role: 'hospital-user',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/store/vendor-management',
    title: 'Vendors',
    icon: 'store',
    class: '',
    badge: '',
    role: 'hospital-user',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Settings',
    icon: 'settings',
    class: '',
    badge: '',
    role: 'hospital-user',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      // {
      //   path: '/customer/settings/account',
      //   title: 'Account',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   role: 'hospital-user',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: [],
      // },
      {
        path: '/store/settings/profile',
        title: 'Profile',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-user',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ]
  },
  {
    path: '',
    title: 'Data Uploads',
    icon: 'cloud_upload',
    class: '',
    badge: '',
    role: 'hospital-user',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/store/data-uploads/daily-stocks',
        title: 'Daily Stocks',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-user',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/store/data-uploads/purchase-orders',
        title: 'Purchase Order',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-user',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/store/data-uploads/sales-datas',
        title: 'Sales',
        icon: '',
        class: '',
        badge: '',
        role: 'hospital-user',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/vendor/products-management',
    title: 'Products',
    icon: 'add_shopping_cart',
    class: '',
    badge: '',
    role: 'vendor',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/vendor/reports',
    title: 'Reports',
    icon: 'equalizer',
    class: '',
    badge: '',
    role: 'vendor',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/vendor/ratings',
    title: 'Ratings',
    icon: 'star',
    class: '',
    badge: '',
    role: 'vendor',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/vendor/delivery-schedules',
    title: 'Delivery Schedules',
    icon: 'schedule',
    class: '',
    badge: '',
    role: 'vendor',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/vendor/lead-time',
    title: 'Lead Time',
    icon: 'schedule',
    class: '',
    badge: '',
    role: 'vendor',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/vendor/messages',
    title: 'Messages',
    icon: 'message',
    class: '',
    badge: '',
    role: 'vendor',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Settings',
    icon: 'settings',
    class: '',
    badge: '',
    role: 'vendor',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/vendor/settings/account',
        title: 'Account',
        icon: '',
        class: '',
        badge: '',
        role: 'vendor',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/vendor/settings/profile',
        title: 'Profile',
        icon: '',
        class: '',
        badge: '',
        role: 'vendor',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/vendor/settings/users',
        title: 'Users',
        icon: '',
        class: '',
        badge: '',
        role: 'vendor',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },

    ]
  },
];
