import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APIResponse } from '@models/api-response-data';
import { ModalConfig } from '@models/modal-config';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MtxGridColumn } from '@ng-matero/extensions/grid';
import { ApiService } from '@services/api-service.service';
import { AuthenticationService } from '@services/authentication.service';
import { ApiConstants } from '@utils/api-constants';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-generate-po',
  templateUrl: './generate-po.component.html',
  styleUrls: ['./generate-po.component.scss']
})
export class GeneratePoComponent implements OnInit {

  @ViewChild('generatePO') private modalContent!: TemplateRef<GeneratePoComponent>
  ngbModalRef!: NgbModalRef;
  @Output() submitPO: EventEmitter<any> = new EventEmitter();
  @Input() public modalConfig!: ModalConfig
  @Input() public data!: any;
  @Input() public products: any = []
  private modalRef!: NgbModalRef
  poForm!: FormGroup;
  vendorLists: any;

  constructor(
    private modalService: NgbModal,
    public auth: AuthenticationService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toaster: ToastrService
  ) { }

  columns: MtxGridColumn[] = [
    { header: 'Sl No', field: 'slNo', width: '80px' },
    { header: 'Item Description', field: 'customerItemDescription' },
    {
      header: 'Action',
      field: 'option',
      pinned: 'right',
      right: '0px',
      type: 'button',
      buttons: [
        {
          icon: 'delete',
          tooltip: 'Remove Item',
          type: 'icon',
          click: (record) => this.removeItem(record),
        }
      ]
    }
  ]

  ngOnInit(): void {
    this.vendorList();
    this.poForm = this.formBuilder.group({
      vendorId: ['', [Validators.required]],
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['products'] && !changes['products'].firstChange) {
      this.products = changes['products'].currentValue
    }
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { backdrop: 'static', size: 'lg' })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

  submit() {
    if (this.products.length > 0) {
      this.submitPO.emit({ ...this.poForm.value, products: this.products })
    } else {
      this.toaster.error('Please Select At Least One Item To Continue', "Cart")
    }
  }

  vendorList() {
    this.apiService.parseApiCall(ApiConstants.URL.VENDOR_DROPDOWN_LIST.URI, ApiConstants.URL.VENDOR_LIST.METHOD, {}).subscribe((res: APIResponse) => {
      if (res.success) {
        this.vendorLists = res.data.vendors;
      }
    });
  }

  removeItem(item: any) {
    const itemIndex = this.products.findIndex((e: any) => { return e._id.toString() == item._id.toString() })
    if (itemIndex > -1) {
      this.products.splice(itemIndex, 1)
      this.toaster.success('Item Successfully Removed', item.customerItemDescription)
    }
  }
}
