import { Routes } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const PAGE_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../pages/content-pages/content-pages.module').then(
        (m) => m.ContentPagesModule
      ),
  },
];

export const AUTH_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../auth/auth.module').then((m) => m.AuthModule),
  },
];
